<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1856 7.5862H1.81341M5.14488 1L5.14488 4.19969M11.01 1V4.19969M3.4043 15.0001H12.5946C13.6992 15.0001 14.5946 14.1046 14.5946 13.0001V4.50345C14.5946 3.39889 13.6992 2.50345 12.5946 2.50345H3.4043C2.29973 2.50345 1.4043 3.39888 1.4043 4.50345V13.0001C1.4043 14.1046 2.29973 15.0001 3.4043 15.0001Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
